import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import {
  getTrackingInfoForBookButtonClick,
  getTrackingInfoForNavigateToServicePageClick,
} from '@wix/bookings-analytics-adapter';
import {
  CatalogServiceDto,
  ReservedLocationIds,
} from '@wix/bookings-uou-types';
import { bookingsBookItClick, bookingsCantBookGroupsMessage } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  BookingValidations,
  getNotifyPricingPlanRequest,
} from '../../utils/validation/booking-validations';
import { Navigation } from '../../utils/navigation/navigation';
import { ServiceIntent } from '../../utils/navigation/navigation.const';
import { BookingsApi } from '../../api/BookingsApi';
import { WidgetConfig } from '../../../legacy/types';
import {
  ActionNames,
  BiServiceType,
  ReferralInfo,
} from '../../utils/bi/consts';
import { WidgetViewModel } from '../../viewModel/viewModel';
import settingsParams from '../../components/BookOnline/settingsParams';
import {
  DisplayServicesByOptions,
  FilterServicesByOptions,
} from '../../types/types';
import { ALL_SERVICES } from '../../consts';

export type NavigationAction = (
  service: CatalogServiceDto,
  referralInfo: ReferralInfo,
) => void;

export type HandelNavigationAction = ({
  service,
  intent,
  referralInfo,
  locationId,
}: {
  service: CatalogServiceDto;
  intent: ServiceIntent;
  referralInfo: ReferralInfo;
  locationId?: string;
}) => void;

export const createHandelNavigationAction = ({
  widgetViewModel,
  wixSdkAdapter,
  bookingsApi,
  flowAPI,
  config,
  setProps,
  isAnywhereFlow,
}: {
  widgetViewModel: WidgetViewModel;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsApi: BookingsApi;
  flowAPI: ControllerFlowAPI;
  config: WidgetConfig;
  setProps: Function;
  isAnywhereFlow?: boolean;
}): HandelNavigationAction => {
  return ({
    service,
    intent,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    intent: ServiceIntent;
    referralInfo: ReferralInfo;
  }) => {
    const { controllerConfig, bi } = flowAPI;
    const { compId } = controllerConfig;
    const bookingsValidations = new BookingValidations(wixSdkAdapter);

    const navigation = new Navigation(wixSdkAdapter, compId);
    const activeFeatures = JSON.parse(config.activeFeatures);
    bookingsValidations
      .shouldNavigate(service, activeFeatures, intent, isAnywhereFlow)
      .then(({ canBook, reason }) => {
        if (canBook) {
          const data = {
            service,
            businessName: config.businessInfo.name!,
          };
          const trackingInfo =
            intent === ServiceIntent.BOOK_OFFERING
              ? getTrackingInfoForBookButtonClick(data)
              : getTrackingInfoForNavigateToServicePageClick(data);
          wixSdkAdapter.trackAnalytics(trackingInfo);

          bi?.report(bookingsBookItClick({
            service_id: service.id,
            type: BiServiceType[service.type],
            isPendingApproval: service.schedulePolicy.isPendingApprovalFlow,
            referralInfo,
            ...(intent === ServiceIntent.BOOK_OFFERING
              ? { actionName: ActionNames.NAVIGATE_TO_CALENDAR }
              : {}),
          }));

          const preSelectedStaff = wixSdkAdapter.getUrlQueryParamValue(
            BookingsQueryParams.STAFF,
          );

          const locationId = getLocationId(flowAPI, widgetViewModel);

          navigation
            .navigateToApp({
              service,
              intent,
              staff: preSelectedStaff,
              location: locationId,
            })
            .catch(console.log);
        } else {
          if (reason) {
            if (reason.premiumError) {
              bookingsApi
                .notifyOwnerNonPremiumEnrollmentAttempt()
                .then(() => null)
                .catch(console.error);
            } else if (reason.pricingPlanError) {
              bookingsApi
                .notifyOwnerNonPricingPlanEnrollmentAttempt(
                  getNotifyPricingPlanRequest(service, reason),
                )
                .then(() => null)
                .catch(console.error);
            }
          }

          bi?.report(bookingsCantBookGroupsMessage({
            referralInfo: ReferralInfo.WIDGET,
          }));

          widgetViewModel.showDialog = true;
          setProps({
            widgetViewModel: {
              ...widgetViewModel,
            },
          });
        }
      })
      .catch(console.log);
  };
};

const getLocationId = (
  flowAPI: ControllerFlowAPI,
  widgetViewModel: WidgetViewModel,
) => {
  const { settings } = flowAPI;
  let locationId = '';
  const isListFilterVisible = settings.get(settingsParams.isListFilterVisible);
  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  const displayServicesBy = settings.get(settingsParams.displayServicesBy);
  const selectedLocations = settings.get(settingsParams.selectedLocations);
  const isOnlyOneLocationSelected =
    displayServicesBy === DisplayServicesByOptions.BY_LOCATIONS &&
    selectedLocations?.length === 1;

  if (
    isListFilterVisible &&
    filterServicesBy === FilterServicesByOptions.LOCATIONS
  ) {
    const selectedFilterOption = widgetViewModel.filterOptions.find(
      (option) => option.isSelected,
    )!.id;
    locationId =
      selectedFilterOption !== ALL_SERVICES &&
      selectedFilterOption !== ReservedLocationIds.OTHER_LOCATIONS
        ? selectedFilterOption
        : '';
  } else if (isOnlyOneLocationSelected) {
    locationId = selectedLocations[0];
  }
  return locationId;
};
